import { RichText } from 'prismic-dom'
/* eslint-disable-next-line import/named */
import { filter, map } from 'lodash'

export const mapSliceDataToPayload = (sliceData: PrismicPopinSliceData) => ({
  title: RichText.asHtml(sliceData.primary.title),
  text: RichText.asHtml(sliceData.primary.text),
  info: RichText.asHtml(sliceData.primary.info),
  primaryCta: RichText.asHtml(sliceData.primary['primary-cta']),
  primaryCtaHref: sliceData.primary['primary-cta-href'],
  secondaryCta:
    sliceData.primary['secondary-cta'] &&
    RichText.asHtml(sliceData.primary['secondary-cta']),
  secondaryCtaHref: sliceData.primary['secondary-cta-href'],
  picture: sliceData.primary.picture,
})

export const sliceLookup = (
  document: { body?: PrismicPopinSliceData[] },
  sliceType: string,
  sliceLabel?: string
) => {
  if (!document) return []

  const { body } = document

  const slices = filter(
    body,
    (slice: PrismicPopinSliceData) => slice.slice_type === sliceType
  )

  if (!sliceLabel) {
    return filter(
      slices,
      (slice: PrismicPopinSliceData) => slice.slice_label === null
    )
  }

  return filter(
    slices,
    (slice: PrismicPopinSliceData) => slice.slice_label === sliceLabel
  )
}

export const extractPopinPayloadFromBody = (
  document: { body?: PrismicPopinSliceData[] },
  sliceLabel?: string
) => {
  const slices = sliceLookup(document, 'popin', sliceLabel)

  return map(slices, (slice) => mapSliceDataToPayload(slice))
}
export const extractPopinDevisFromBody = (
  document: { body?: PrismicPopinSliceData[] },
  sliceLabel?: string
) => {
  const slices = sliceLookup(document, 'popindevis', sliceLabel)

  return map(slices, (slice) => mapSliceDataToPayload(slice))
}
